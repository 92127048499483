import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Input from '../global/fields/Input';
import FormsyCheckbox from '../global/fields/Checkbox';
import { Consumer } from '../../contexts/Context';
import { setErrorPopupMessage } from '../../store/actions/ui.actions';
import { signInUser } from '../../store/actions/user.actions';
import { getIsUserDataPending } from '../../store/selectors/user.selectors';

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSignIn: false,
    };
  }

  enableButton(queryParams) {
    this.setState({
      canSignIn: true,
      queryParams,
    });
  }

  disableButton() {
    this.setState({
      canSignIn: false,
    });
  }

  handleSubmit(data) {
    const { queryParams } = this.state;
    const { signInUserAction } = this.props;

    this.setState({
      canSignIn: false,
    });

    signInUserAction(data, queryParams);
  }

  render() {
    const { isUserDataPending } = this.props;

    return (
      <Consumer>
        {(context) => (
          <div className="auth">
            <div className="auth-box">
              <div className="auth-form form-fancy signin">
                <fieldset>
                  <legend className="auth-header-wrapper">
                    <h2 className="center">Welcome back</h2>
                  </legend>
                  <Formsy
                    onValidSubmit={this.handleSubmit.bind(this)}
                    onValid={this.enableButton.bind(this, context.queryParams)}
                    onInvalid={this.disableButton.bind(this)}
                  >
                    <div className="form-fancy-wrapper">
                      <Input
                        autocapitalize="none"
                        name="email"
                        validations="isEmail"
                        validationError="Invalid Email"
                        required
                        label="Enter Email*"
                      />
                      <Input name="password" type="password" required label="Enter Password*" />
                    </div>
                    <div className="auth-form-wrapper">
                      <div className="auth-form-col">
                        <button
                          type="submit"
                          disabled={!!(!this.state.canSignIn || isUserDataPending)}
                          className={classNames(
                            'log-in',
                            'btn',
                            {
                              'btn-charcoal disabled': !this.state.canSignIn || isUserDataPending,
                              'btn-yellow': this.state.canSignIn && !isUserDataPending,
                            },
                          )}
                        >
                          Log In
                        </button>
                      </div>
                      <div className="auth-form-wrapper-flex">
                        <div className="auth-form-col charcoal">
                          <FormsyCheckbox
                            name="remember"
                            validationError=""
                            label="Remember me?"
                          />
                        </div>
                        <div className="auth-form-col charcoal forgot-password">
                          <Link to="forgot-password">Forgot password?</Link>
                        </div>
                      </div>
                      <div className="center">
                        <Link to="order">
                          <button
                            className="look-up-order btn btn-latte"
                          >
                            <i
                              className="icon icon-order-lookup"
                            />
                          LOOK UP ORDER
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Formsy>
                </fieldset>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserDataPending: getIsUserDataPending(state),
});

const mapDispatchToProps = {
  setErrorPopupMessageAction: setErrorPopupMessage,
  signInUserAction: signInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
