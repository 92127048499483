import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import SignInContainer from './SignIn';
import CreateContainer from './Create';
import LoadingContainer from '../global/Loading';
import { getHrefLang } from '../../store/selectors/general.selectors';
import generateUrlWithHrefLang from '../../common/generateUrlWithHrefLang';
import paths from '../../paths';
import { openCart } from '../../store/actions/ui.actions';
import { getIsUserLoggedIn } from '../../store/selectors/user.selectors';
import { withScrollingView } from '../../common/WithScrollingView';

import { isLoading } from '../../../../utils/Utils';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: true,
      displayInput: false,
    };
    this.switchOn = this.switchOn.bind(this);
    this.switchOff = this.switchOff.bind(this);
  }

  componentDidMount() {
    this.props.openCartAction(false);
    window.scrollTo(0, 0);
  }

  switchOn(e) {
    e.stopPropagation();
    this.setState({
      activeComponent: false,
    });
  }

  switchOff() {
    this.setState({
      activeComponent: true,
    });
  }

  hideInput() {
    this.setState({
      displayInput: false,
    });
  }

  showInput() {
    this.setState({
      displayInput: true,
    });
  }

  render() {
    const {
      isUserLoggedIn,
      hrefLang,
    } = this.props;
    if (isLoading(this.props)) {
      return <LoadingContainer />;
    }
    return !isUserLoggedIn ? (
      <div className="login-create-wrapper">
        <div
          onMouseEnter={this.switchOff}
          onClick={this.switchOff}
          className={`login-create-component create ${this.state.activeComponent ? 'active' : 'inactive'}-component`}
        >
          <CreateContainer
            history={this.props.history}
            displayInput={true}
            showInput={this.showInput.bind(this)}
            hideInput={this.hideInput.bind(this)}
          />
        </div>
        <div
          onMouseEnter={this.switchOn}
          onClick={this.switchOn}
          className={`login-create-component sign-in ${!this.state.activeComponent ? 'active' : 'inactive'}-component`}
        >
          <SignInContainer
            history={this.props.history}
          />
        </div>
      </div>
    ) : (
      <Redirect to={generateUrlWithHrefLang({ hrefLang, endpoint: paths.ACCOUNT })} />
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  hrefLang: getHrefLang(state),
});

const mapDispatchToProps = {
  openCartAction: openCart,
};

export default compose(
  withRouter,
  withScrollingView(mapStateToProps, mapDispatchToProps),
)(Login);
