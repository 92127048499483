import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import qs from  'querystring';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Input from '../global/fields/Input';
import { Consumer } from '../../contexts/Context';
import { setErrorPopupMessage } from '../../store/actions/ui.actions';
import { createUser } from '../../store/actions/user.actions';
import { getIsUserDataPending } from '../../store/selectors/user.selectors';
import { fetchCheckoutUrlAndRedirect } from '../../store/actions/cart.actions';

addValidationRule('isNotBlankString', (values, value) => value && value.trim().length > 0);

export class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canCreate: false,
    };

    this.disableButton = this.disableButton.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  enableButton(queryParams) {
    this.setState({
      canCreate: true,
      queryParams,
    });
  }

  disableButton() {
    this.setState({
      canCreate: false,
    });
  }

  handleSubmit(data) {
    const { queryParams } = this.state;

    this.props.createUserAction(data, queryParams);
  }

  redirect(queryParams) {
    const {
      history,
      fetchCheckoutUrlAndRedirectAction,
    } = this.props;
    const query = cloneDeep(queryParams);
    const redirect  = `${decodeURIComponent(queryParams.redirect_to)}`;

    delete query.redirect_to;

    if (redirect === '/checkout') {
      return fetchCheckoutUrlAndRedirectAction();
    }

    return history.push(`${redirect}?${qs.stringify(query)}`);
  }

  render() {
    const { isUserDataPending } = this.props;

    return (
      <Consumer>
        {(context) => (
          <div className="auth">
            <div className="auth-box">
              {(this.props.displayInput || (context.queryParams && !context.queryParams.redirect_to)) ? (
                <div className="auth-form  form-fancy">
                  <fieldset>
                    <legend className="auth-header-wrapper">
                      <h2 className="center">I&apos;m new here</h2>
                    </legend>
                    <Formsy onValidSubmit={this.handleSubmit.bind(this)} onValid={this.enableButton.bind(this)} onInvalid={this.disableButton}>
                      <div className="form-fancy-wrapper">
                        <Input
                          autocapitalize="none"
                          name="firstName"
                          validations="isNotBlankString"
                          validationError="Name cannot be blank spaces"
                          required
                          label="First Name*"
                        />
                        <Input
                          autocapitalize="none"
                          name="lastName"
                          validations="isNotBlankString"
                          validationError="Name cannot be blank spaces"
                          required
                          label="Last Name*"
                        />
                        <Input autocapitalize="none" name="email" validations="isEmail" validationError="Invaild Email" required label="Email Address*" />
                        <Input name="password" type="password" validations={{ matchRegexp:/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ }}  validationError="At least 8 characters with 1 capital letter, 1 number and 1 special character" required label="Password*" />
                      </div>
                      <div className="auth-form-wrapper">
                        <div className="auth-form-col">
                          <button
                            type="submit"
                            disabled={!!(!this.state.canCreate || isUserDataPending)}
                            className={classNames(
                              'create-account',
                              'btn',
                              {
                                'btn-charcoal disabled': !this.state.canCreate || isUserDataPending,
                                'btn-yellow': this.state.canCreate && !isUserDataPending,
                              },
                            )}
                          >
                            Create an account
                          </button>
                        </div>
                      </div>
                    </Formsy>
                  </fieldset>
                </div>
              ) : (
                <button className="btn btn-charcoal" onClick={this.props.showInput}>Create an Account</button>
              )}
              {(context.queryParams && context.queryParams.redirect_to) ? (
                <div className="auth-box-options">
                  <button
                    className="btn btn-charcoal-reverse-thick"
                    onClick={() => this.redirect(context.queryParams)}
                  >Continue as Guest</button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserDataPending: getIsUserDataPending(state),
});

const mapDispatchToProps = {
  setErrorPopupMessageAction: setErrorPopupMessage,
  createUserAction: createUser,
  fetchCheckoutUrlAndRedirectAction: fetchCheckoutUrlAndRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
